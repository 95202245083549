img {
    /* object-fit: cover; */
    /* width: 100%; */
    /* height: 100%; */
    /* position: fixed; */
    /* z-index: -1; */
    /* position: absolute; */
    /* top: 0; */
    /* z-index: -1; Ensure the video is behind everything else */
    /* rest of your styles */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh; /* Adjust height if you want to cover the full viewport height */
    object-fit: cover;
    z-index: -1; /* Below content but above the page background */
}
  
  .hero-container {
    /* background: url('../images/img-home.jpg') center center/cover no-repeat; */ 
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    padding-top: 80px; /* Adjust this value so that the content of HeroSection starts just below the Navbar */
    position: relative;
    /* z-index: 1; Below the navbar */
    z-index: 2; /* Make sure this is above the fixed video background */
}
  
  .hero-container > h1 {
    color: #fff;
    font-size: 65px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 24px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
  